<template>
  <button class="btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'tt-button'
}
</script>

<style lang="sass" scoped>
  .btn
    padding: 10px
    background-color: #2aabee
    color: white
    border: 0
    margin-bottom: 16px
</style>
